import { graphql, Link } from "gatsby";
import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../containers/Layout";
import { trackView } from "../../utils/analytics/index";
import { Category, Name } from "../../utils/analytics/constants";
import BlogList from "../../components/Blog/BlogList";
import EmbeddedCta from "../../components/Blog/EmbeddedCta";
import ResultsNavigation from "../../components/Blog/ResultsNavigation";
import { NavigationBarVariant } from "../../components/NavigationBar/models";
import GradientSection from "../../containers/GradientSection";
import NavigationBar from "../../components/NavigationBar";
import EmbeddedCtaPop from "../../components/Blog/EmbeddedCtaPop";
import LeftArrow from "../../images/left-arrow.svg";
import cn from "classnames";
import { marketplaces } from "../../constants/filters";
import BlogCta from "../../components/Blog/BlogCTA";
import SEO from "../../components/Seo";
import { useI18next } from "gatsby-plugin-react-i18next";
import CustomBreadcrumb from "../../components/CustomBreadcrumb";
import { BreadcrumbPageType, formatBreadcrumb } from "../../utils/breadcrumb";

const BlogCategory = props => {
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [isTop, setIsTop] = useState<boolean>(true);
  const [activeMarketplace, setActiveMarketplace] = useState<string>("");

  const { t, language } = useI18next();
  const { pageContext } = props;
  const { categoryCta } = pageContext;

  const categoriesAvaliable = props?.data?.categories?.edges?.map(cat => {
    if (cat?.node?.name !== null) {
      return {
        language: cat.node.node_locale,
        url: `/blog/category/${cat.node.name
          .split(" ")
          .join("-")
          .toLowerCase()}/`
      };
    }
  });

  const handleScroll = () => {
    if (window && window?.pageYOffset > 0) {
      setIsTop(false);
    } else {
      setIsTop(true);
    }
  };

  useEffect(() => {
    trackView(Name.BlogCategory, {
      blog_post_category: pageContext.name,
      category: Category.PageView
    });
    window?.addEventListener("scroll", handleScroll);
  }, []);

  const updatedCrumbs = useMemo(
    () => formatBreadcrumb(pageContext, BreadcrumbPageType.BlogCategory),
    []
  );

  return (
    <>
      <SEO title={t(pageContext.name)} />

      {/* // Hide nav from layout but retain other stuff */}
      <Layout inlineSwitcher hideNav>
        {/* Pull in blog nav variation */}
        <SEO
          title={`${t(pageContext.name)} | eCommerce Advertising Blog`}
          description={
            "Perpetua's blog for eCommerce advertising news, insights tips and content for sellers, brands and agencies looking to grow their business."
          }
        />
        <NavigationBar
          setActiveFilter={setActiveMarketplace}
          activeFilter={activeMarketplace}
          variant={NavigationBarVariant.blog}
          filters={marketplaces}
          setSearchQuery={setSearchQuery}
          localesAvailable={categoriesAvaliable}
        />
        <div className="hidden md:block">
          <ResultsNavigation
            isTop={isTop}
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
            setActiveMarketplace={setActiveMarketplace}
            activeMarketplace={activeMarketplace}
          />
        </div>
        <GradientSection
          className={`pb-20 bg-gradient-to-b ${pageContext.backgroundColor} to-white`}
        >
          <div className="pt-22 md:pt-72">
            <div className="mb-8">
              <CustomBreadcrumb crumbs={updatedCrumbs} />
            </div>
            <div className="md:hidden pb-8">
              {/* Mobile only */}
              <div className={cn("flex justify-between pt-3 items-center")}>
                <Link
                  className="text-black py-4 flex items-center"
                  to={`${
                    language !== "en-US" ? `/${language}/blog` : "/"
                  }blog/`}
                >
                  <LeftArrow className="mr-3" />
                  blog
                </Link>
                <EmbeddedCtaPop />
              </div>
            </div>
            <BlogList
              categoryPage
              publications={props?.data?.allContentfulPublication?.edges}
              activeMarketplace={activeMarketplace}
              title={t(pageContext.name)}
              pagination={true}
              color={pageContext.textColor}
              setActiveMarketplace={setActiveMarketplace}
              ctaPosition={categoryCta?.position}
              ctaSize={categoryCta?.size[0]}
              CTA={() =>
                categoryCta && (
                  <BlogCta
                    title={categoryCta?.title}
                    description={categoryCta?.description}
                    image={categoryCta?.logo}
                    variant={categoryCta?.variations[0]}
                    ctaSize={categoryCta?.size[0]}
                    buttonLink={categoryCta?.buttonLink}
                    buttonText={categoryCta?.buttonText}
                  />
                )
              }
            />
            <div className="mb-20">
              <EmbeddedCta
                fullWidth
                buttonText="Sign Up"
                title="Insights to help grow profit"
                textFieldPlaceholder="Email address"
                successText="Thanks for signing up! We promise only the best updates and growth rocket fuel coming your way."
              />
            </div>
          </div>
        </GradientSection>
      </Layout>
    </>
  );
};

export default BlogCategory;

export const query = graphql`
  query CategoryByName($contentfulId: String!, $language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    categories: allContentfulCategory(
      filter: { contentful_id: { eq: $contentfulId } }
    ) {
      edges {
        node {
          name
          node_locale
        }
      }
    }
    allContentfulPublication(
      sort: { order: DESC, fields: publishDate }
      filter: {
        categories: { elemMatch: { contentful_id: { eq: $contentfulId } } }
        node_locale: { eq: $language }
      }
    ) {
      edges {
        node {
          id
          title
          slug
          node_locale
          publishDate(formatString: "MMMM Do, YYYY")
          timeToRead
          bodyPreview
          marketplace
          body {
            raw
          }
          categories {
            contentful_id
            name
          }
          authors {
            name
          }
          bannerImage {
            gatsbyImageData(layout: FULL_WIDTH)
          }
        }
      }
    }
  }
`;
