import React, { useEffect, useState } from "react";
import FeaturedCard from "../FeaturedCard";
import Card from "../Card";
import Header, { HeaderVariant } from "../../../components/Header";
import cn from "classnames";
import Chevron from "../../../images/chevron.svg";
import { Link } from "gatsby";
import { useI18next } from "gatsby-plugin-react-i18next";

interface BlogListProps {
  publications: any;
  title?: string;
  CTA?: any | null;
  ctaPosition?: any;
  color?: string;
  button?: string;
  ctaSize?: string;
  categoryPage?: boolean;
  searchPage?: boolean;
  pagination?: boolean;
  activeMarketplace?: string;
  setActiveMarketplace?: any;
  limit?: number;
}

const BlogList: React.FC<BlogListProps> = props => {
  const {
    publications = [],
    title,
    CTA,
    ctaPosition,
    ctaSize,
    button,
    color = "text-gray-4-dark",
    categoryPage = false,
    searchPage = false,
    pagination = false,
    activeMarketplace = "all",
    setActiveMarketplace,
    limit
  } = props;

  const [pageNumber, setPageNumber] = useState(0);
  const [pageSplitArticles, setPageSplitArticles] = useState([]);
  const { t, language } = useI18next();

  useEffect(() => {
    const splitArticles: any = [];
    let chunkSize = limit ? limit : 13;

    if (ctaSize === "medium") {
      chunkSize = limit ? limit - 1 : 13 - 1;
    }

    const filteredPublications =
      pagination && activeMarketplace !== "" && activeMarketplace !== "all"
        ? publications?.filter(publication => {
            const result = publication?.node ? publication?.node : publication;
            return result.marketplace?.some(
              item =>
                item == activeMarketplace &&
                result.slug !== null &&
                result.title !== null
            );
          })
        : publications;

    for (let i = 0; i < filteredPublications?.length; i += chunkSize) {
      const chunk = filteredPublications.slice(i, i + chunkSize);
      splitArticles.push(chunk);
    }

    setPageNumber(0);
    setPageSplitArticles(splitArticles);
  }, [publications.length, activeMarketplace]);

  useEffect(() => {
    if (pagination) {
      let url = new URL(window?.location.href);
      url.search = "";
      let currentURLParams = new URLSearchParams(window?.location.search);
      let marketplace = currentURLParams.get("marketplace");
      let page = parseInt(currentURLParams.get("page")) - 1;
      marketplace
        ? setActiveMarketplace(marketplace)
        : setActiveMarketplace("");
      page
        ? setPageNumber(parseInt(currentURLParams.get("page")) - 1)
        : setPageNumber(0);

      if (!page && !marketplace) {
        setPageNumber(0);
      }
    }
  }, []);

  return (
    <>
      <div className="w-full flex flex-col sm:flex-row justify-between items-center mb-8">
        <Header className="w-auto" variant={HeaderVariant.h2}>
          <span
            className={`${color} text-3xl sm:text-4xl font-semibold lg:text-5xl md:font-bold flex`}
          >
            {searchPage && "search: "}
            {title}
          </span>
        </Header>
        {!categoryPage && !searchPage && (
          <Link
            to={`${
              language !== "en-US" ? `/${language}/blog` : "/blog"
            }/category/${title?.split(" ").join("-").toLocaleLowerCase()}/`}
          >
            <button
              className={cn(
                button,
                "text-white px-6 py-3 text-2xl rounded-xl font-medium focus:outline-none flex items-center mb-8 group lg:mb-12"
              )}
            >
              {`${t("All")}  ${title}`}
              <Chevron className="fill-white w-2 ml-4 transition transition-delay-300 ease-in -translate-x-1 group-hover:translate-x-1" />
            </button>
          </Link>
        )}
      </div>
      {pageSplitArticles.length > 0 ? (
        <ul className="grid grid-cols-12 lg:pb-6 sm:-mx-4">
          {pageSplitArticles[pageNumber]?.map((item, i) => {
            if (i < 1) {
              return (
                <div key={i} className="col-span-12 sm:px-4">
                  <FeaturedCard
                    color={color}
                    publication={item?.node ? item?.node : item}
                  />
                </div>
              );
            }
            if (CTA && i === ctaPosition - 1) {
              return (
                <div
                  key={i}
                  className="sm:px-4 pb-8 col-span-12 sm:col-span-6 lg:col-span-3"
                >
                  <CTA />
                </div>
              );
            } else {
              return (
                <div
                  key={i}
                  className={"col-span-12 sm:col-span-6 lg:col-span-3 sm:px-4"}
                >
                  <Card color={color} publication={item?.node ?? item} />
                </div>
              );
            }
          })}
        </ul>
      ) : (
        <Header
          className="lg:text-3xl pb-32 md:pl-12"
          variant={HeaderVariant.h2}
        >
          {t("No articles available")}
        </Header>
      )}
      <div
        className={cn(
          (categoryPage || searchPage) && "border-t-2 border-indigo-1 pb-10"
        )}
      >
        {pagination && pageSplitArticles.length > 0 && (
          <div className="py-3 flex items-center">
            <p className="mr-3 text-md text-indigo-1 font-semibold">
              Page {pageNumber + 1 || 1} of {pageSplitArticles.length}
            </p>
            <div className="bg-indigo-1 h-7 w-[2px]"></div>
            {pageSplitArticles?.map((item, i) => {
              return (
                <Link
                  key={item?.node?.id}
                  className={cn(
                    "ml-3 w-7 h-7 pt-[1px] rounded-full flex items-center justify-center focus:outline-none",
                    pageNumber === i
                      ? "bg-indigo-1 text-white"
                      : "text-indigo-1"
                  )}
                  to={
                    categoryPage
                      ? `/blog/category/${title
                          ?.split(" ")
                          .join("-")
                          .toLocaleLowerCase()}/?page=${i + 1}`
                      : `/blog/search?s=${title}&page=${i + 1}`
                  }
                  onClick={() => {
                    setPageNumber(i);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                  }}
                >
                  {i + 1}
                </Link>
              );
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default BlogList;
