export enum BreadcrumbPageType {
  BlogCategory = "blog-category",
  BlogIndex = "blog-index",
  BlogSingle = "blog-single",
  VideoIndex = "video-index",
  VideoCategory = "video-category",
  ResourcesSingle = "resources-single",
  WebinarSingle = "webinar-single"
}

export function formatBreadcrumb(pageContext, type) {
  if (
    pageContext?.breadcrumb?.crumbs === undefined ||
    pageContext?.breadcrumb === undefined
  ) {
    return [];
  }

  let crumbsCopy = [...pageContext.breadcrumb.crumbs];

  if (
    type === BreadcrumbPageType.BlogIndex ||
    type === BreadcrumbPageType.VideoIndex
  ) {
    if (pageContext.language !== "en-US") {
      crumbsCopy[0].pathname = `/${pageContext.language}/`;
      crumbsCopy.splice(1, 1);
    }
  } else if (type === BreadcrumbPageType.BlogSingle) {
    const newCrumbs = pageContext.breadcrumb.crumbs.slice(
      0,
      pageContext.breadcrumb.crumbs.length - 1
    );
    newCrumbs.push({
      pathname:
        pageContext.language !== "en-US"
          ? `/${pageContext.language}/blog/`
          : "/blog/",
      crumbLabel: "Blog"
    });
    newCrumbs.push(
      pageContext.breadcrumb.crumbs[pageContext.breadcrumb.crumbs.length - 1]
    );
    const lastCrumb = newCrumbs[newCrumbs.length - 1];
    lastCrumb.crumbLabel = pageContext?.title;
    crumbsCopy = newCrumbs;
  } else if (type === BreadcrumbPageType.BlogCategory) {
    if (pageContext.language !== "en-US") {
      crumbsCopy[0].pathname = `/${pageContext.language}/`;
      crumbsCopy.splice(1, 1);
      crumbsCopy.splice(2, 1);
    } else {
      crumbsCopy.splice(2, 1);
    }
    const lastCrumb = crumbsCopy[crumbsCopy.length - 1];
    lastCrumb.crumbLabel = pageContext.name;
  } else if (type === BreadcrumbPageType.VideoCategory) {
    if (pageContext.language !== "en-US") {
      crumbsCopy[0].pathname = `/${pageContext.language}/`;
      crumbsCopy.splice(1, 1);
    }
    const lastCrumb = crumbsCopy[crumbsCopy.length - 1];
    lastCrumb.crumbLabel = pageContext.title;
  } else if (type === BreadcrumbPageType.ResourcesSingle) {
    const lastCrumb = crumbsCopy[crumbsCopy.length - 1];
    lastCrumb.crumbLabel = pageContext.title;
    crumbsCopy.splice(2, 1);
  } else if(type === BreadcrumbPageType.WebinarSingle) {
    const lastCrumb = crumbsCopy[crumbsCopy.length - 1];
    lastCrumb.crumbLabel = pageContext.title;
  }

  return crumbsCopy;
}
