import React from "react";
import Section from "../../../containers/Section";
import Socials from "../Socials/index";
import cn from "classnames";
import Search from "../Search";
import EmbeddedCtaPop from "../EmbeddedCtaPop";
import { Link } from "gatsby";
import LeftArrow from "../../../images/left-arrow.svg";
import DropdownSelector from "../../DropdownSelector";
import { marketplaces } from "../../../constants/filters";
import { useI18next } from "gatsby-plugin-react-i18next";

interface ResultsNavigationProps {
  active?: string;
  isTop?: boolean;
  searchQuery?: any;
  setSearchQuery?: any;
  videoPage?: boolean;
  setActiveMarketplace?: any;
  activeMarketplace?: string;
}

// Desktop only
const ResultsNavigation: React.FC<ResultsNavigationProps> = props => {
  const {
    isTop,
    setSearchQuery = null,
    videoPage = false,
    setActiveMarketplace = null,
    activeMarketplace = "",
  } = props;


  const { language } = useI18next();

  return (
    <>
      <div
        className={cn(
          "w-full fixed z-50 py-4",
          !isTop
            ? "shadow-xl bg-white transition duration-200 ease"
            : "bg-white",
          language !== "en-US" ? "top-[60px]" : "top-[90px] lg:top-[99px]"
        )}
      >
        <Section className="flex-col items-center w-full">
          <div className="flex justify-between pt-1">
            <div className="flex">
              {setSearchQuery && (
                <div className="relative rounded-md min-w-[278px] mr-6">
                  {language === "en-US" && (
                    <Search setSearchQuery={setSearchQuery} />
                  )}
                </div>
              )}
              {!videoPage && language === "en-US" && (
                <DropdownSelector
                  activeFilter={activeMarketplace}
                  filters={marketplaces}
                  setActiveFilter={setActiveMarketplace}
                />
              )}
            </div>
            <Socials />
          </div>
          <div className={cn("flex justify-end pt-4 items-center")}>
            <EmbeddedCtaPop />
          </div>
        </Section>
      </div>
    </>
  );
};

export default ResultsNavigation;
