
export const determinePrefix = (locale) => {
    let prefix = "";

    if (locale === "en-US") {
        return (prefix = "");
      } else if (locale === "ja") {
        return (prefix = "jp");
      } else if (locale === "de") {
        return (prefix = "de");
      } else {
        return (prefix = locale);
      }
};

// returns clean slug without starting slash
export const convertBlogSlug = (slug: string, locale?: string, prefix?: boolean) => {
    const newSlug = slug?.split("-")[0] === "blog" ? slug : `blog-${slug}`;

    if(locale){
      return locale === "en-US" ? `/${newSlug}/` : `${prefix !== false ? `/${determinePrefix(locale)}/` : "/"}${newSlug}/`;
    }else{
      return `/${newSlug}/`;
    }
};