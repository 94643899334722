import React from "react";
import cn from "classnames";
import Icon, { IconType } from "../Icon";
import { Helmet } from "gatsby-plugin-react-i18next";

interface CustomBreadcrumbProps {
  crumbs: {
    crumbLabel: string;
    pathname: string;
  }[];
}

const CustomBreadcrumb: React.FC<CustomBreadcrumbProps> = props => {
  const { crumbs } = props;

  const lastPage = crumbs?.length > 0 ? crumbs?.length - 1 : 0;

  const breadcrumbSchema = {
    "@context": "https://schema.org",
    "@type": "BreadcrumbList",
    itemListElement: crumbs?.map((crumb, index) => {
      return {
        "@type": "ListItem",
        position: index,
        name: crumb?.crumbLabel,
        item: `https://perpetua.io${crumb?.pathname}`
      };
    })
  };

  return (
    <div className="flex flex-row">
      {crumbs &&
        crumbs?.map((crumb, index) => {
          return (
            <div key={crumb?.pathname} className="flex flex-row items-center">
              {lastPage === index ? (
                <>
                  <div className="text-indigo-2-light font-semibold group relative lowercase text-sm">
                    <span
                      className={cn(
                        "lowercase",
                        lastPage === index && "text-indigo-3-dark"
                      )}
                    >
                      {crumb?.crumbLabel}
                    </span>
                  </div>
                </>
              ) : (
                <>
                  <a
                    className="text-indigo-2-light font-semibold group relative lowercase text-sm"
                    href={crumb?.pathname}
                  >
                    <span
                      className={cn(
                        "lowercase",
                        lastPage === index && "text-indigo-3-dark"
                      )}
                    >
                      {crumb?.crumbLabel}
                    </span>
                  </a>
                </>
              )}

              {index !== lastPage && (
                <span className="text-indigo-1 font-semibold mx-2">
                  <Icon iconType={IconType.crumbSeparator} />
                </span>
              )}
            </div>
          );
        })}
      <Helmet
        script={[
          {
            type: "application/ld+json",
            innerHTML: JSON.stringify(breadcrumbSchema)
          }
        ]}
      />
    </div>
  );
};

export default CustomBreadcrumb;
