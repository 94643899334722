import { Link } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Icon, { IconType } from "../../Icon";
import { convertBlogSlug } from "../../../utils/blog";

interface CardProps {
  publication: any;
  color?: any;
}

const Card: React.FC<CardProps> = props => {
  const { publication, color } = props;

  const image =
    (publication?.bannerImage && publication?.bannerImage?.gatsbyImageData) ||
    getSrc(publication?.bannerImage);

  const truncate = (str: string, n: number) => {
    return str?.length > n ? str?.substring(0, n - 1) + "..." : str;
  };

  return (
    <Link
      to={convertBlogSlug(publication.slug, publication.node_locale)}
      className="flex h-full flex-col justify-start relative pb-8 group"
    >
      <div className="h-48 sm:h-50 md:h-44 lg:h-36 xl:h-44 overflow-hidden relative rounded-xl">
        <GatsbyImage
          className="min-w-full  absolute top-1/2 left-1/2 scale-110 lg:scale-110 -translate-y-1/2 -translate-x-1/2 group-hover:scale-100 transition duration-200 ease-in"
          image={image}
          alt="preview"
        />
        <div className="absolute opacity-0 w-full h-full bg-gradient-to-br from-blue-1 to-purple-1 transition duration-200 ease-in group-hover:opacity-50"></div>
      </div>
      <div className="flex flex-col flex-grow">
        <div className="flex justify-between pt-3 pb-2">
          <p className={`${color} text-base`}>
            {publication?.categories?.[0].name}
          </p>
          <p className="text-base font-medium">
            <span className="ml-auto flex">
              {`${publication?.timeToRead || 0} min`}
              <Icon iconType={IconType.clock} className="ml-3" />
            </span>
          </p>
        </div>
        <Header
          className="flex flex-grow group-hover:text-indigo-1"
          variant={HeaderVariant.h3}
        >
          <span className="text-lg font-semibold">
            {truncate(publication?.title, 120)}
          </span>
        </Header>
        <p className="text-gray-2 text-base bottom-0 mt-6">
          {publication?.publishDate}
        </p>
      </div>
    </Link>
  );
};

export default Card;
