/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import ogImageDE from "src/assets/perpetua-io-ogimage-DE.png";
import ogImageEN from "src/assets/perpetua-io-ogimage-EN.png";
import ogImageJA from "src/assets/perpetua-io-ogimage-JA.png";
import ogImageCN from "src/assets/perpetua-io-ogimage-CN.png";
import { useI18next } from "gatsby-plugin-react-i18next";

interface Props {
  description?: string;
  lang?: string;
  meta?: JSX.IntrinsicElements["meta"][];
  link?: JSX.IntrinsicElements["link"][];
  title: string;
  image?: string;
  schema?: any;
  canonical?: any;
  robots?: any;
}

const SEO: React.FC<Props> = props => {
  const {
    description,
    lang = "en",
    meta = [],
    link = [],
    title,
    image,
    schema,
    robots,
    canonical = null
  } = props;

  const data = useStaticQuery(
    graphql`
      query {
        site: site {
          siteMetadata {
            title
            description
            author
            siteUrl
          }
        }
      }
    `
  );

  const openGraphImageObj = {
    de: ogImageDE,
    ja: ogImageJA,
    "zh-CN": ogImageCN,
    "en-US": ogImageEN
  };

  const { language, languages, originalPath } = useI18next();
  const metaDescription = description || data.site.siteMetadata.description;
  const defaultTitle = title || data.site.siteMetadata.title;
  const titleTemplate =
    language == "ja" && !defaultTitle.includes("Perpetua(パーペチュア)")
      ? `${defaultTitle} - Perpetua(パーペチュア)`
      : defaultTitle.includes("| Perpetua")
      ? defaultTitle
      : `${defaultTitle} | Perpetua`;
  const ogImage = image
    ? image
    : `https://perpetua.io${openGraphImageObj[language]}`;
  const location = useLocation();
  const canonicalUrl = canonical
    ? canonical
    : data.site.siteMetadata.siteUrl + location.pathname;
  const languageLinks = schema
    ? schema.languagesAvailable
        .filter(l => l && l.language !== language)
        .map(({ language, url }) => ({
          href:
            data.site.siteMetadata.siteUrl +
            `${language !== "en-US" ? "/" + language : ""}` +
            url,
          hreflang: language !== "en-US" ? language : "en",
          rel: "alternate"
        }))
    : languages.map(lang => {
        if (language !== lang) {
          return {
            href:
              data.site.siteMetadata.siteUrl +
              `${lang !== "en-US" ? "/" + lang : ""}` +
              originalPath,
            hreflang: lang !== "en-US" ? lang : "en",
            rel: "alternate"
          };
        } else {
          return {};
        }
      });

  const robtsMeta =
    robots && robots !== null && robots != undefined ? robots : "index, follow";

  return (
    <Helmet
      htmlAttributes={{
        lang: language !== "en-US" ? language : "en"
      }}
      title={title}
      titleTemplate={titleTemplate}
      script={[
        {
          type: "application/ld+json",
          innerHTML: schema && JSON.stringify(schema)
        }
      ]}
      link={(
        [
          {
            rel: `canonical`,
            href: canonicalUrl
          },
          ...languageLinks
        ] as JSX.IntrinsicElements["link"][]
      ).concat(link)}
      meta={(
        [
          {
            name: `robots`,
            content: robtsMeta
          },
          {
            name: `description`,
            content: metaDescription
          },
          {
            name: `title`,
            property: `og:title`,
            content: titleTemplate
          },
          {
            property: `og:description`,
            content: metaDescription
          },
          {
            name: `image`,
            property: `og:image`,
            content: ogImage
          },
          {
            property: `og:type`,
            content: `website`
          },
          {
            name: `twitter:card`,
            content: `summary_large_image`
          },
          {
            name: `twitter:creator`,
            content: data.site.siteMetadata?.author || ``
          },
          {
            name: `twitter:title`,
            content: titleTemplate
          },
          {
            name: `twitter:description`,
            content: metaDescription
          },
          {
            name: `twitter:image`,
            content: ogImage
          }
        ] as JSX.IntrinsicElements["meta"][]
      ).concat(meta)}
    />
  );
};

export default SEO;
