import { Link } from "gatsby";
import { GatsbyImage, getSrc } from "gatsby-plugin-image";
import React from "react";
import Header, { HeaderVariant } from "../../Header";
import Icon, { IconType } from "../../Icon";
import { convertBlogSlug } from "../../../utils/blog/index";
import { useI18next } from "gatsby-plugin-react-i18next";

interface FeaturedCardProps {
  publication: any;
  color?: any;
}

const FeaturedCard: React.FC<FeaturedCardProps> = props => {
  const { publication, color } = props;

  const { t } = useI18next();

  const image =
    (publication?.bannerImage && publication?.bannerImage?.gatsbyImageData) ||
    getSrc(publication?.bannerImage) ||
    null;

  return (
    <Link
      to={convertBlogSlug(publication.slug, publication.node_locale)}
      className="grid lg:grid-cols-2 lg:mb-12 group"
    >
      <div className="col-span-1 h-48 sm:h-80 lg:mr-3 overflow-hidden relative rounded-2xl">
        <GatsbyImage
          className="w-full absolute top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 scale-105 lg:scale-110 group-hover:scale-100 transition duration-200 ease-in"
          image={image}
          alt="preview"
        />
        <div className="absolute opacity-0 w-full h-full bg-gradient-to-br from-blue-1 to-purple-1 transition duration-200 ease-in group-hover:opacity-50"></div>
      </div>
      <div className="col-span-1 flex flex-col justify-center py-9 lg:px-8">
        <Header
          variant={HeaderVariant.h3}
          className="text-3xl md:text-4xl mb-3 group-hover:text-indigo-1"
        >
          {publication.title}
        </Header>
        <div className="flex justify-between sm:justify-start">
          <p className={`${color} text-base`}>
            {t(publication?.categories?.[0].name)}
          </p>
          <p className="text-base font-medium ml-4">
            <span className="ml-auto flex items-center">
              {`${publication.timeToRead || 0} min`}
              <Icon iconType={IconType.clock} className="ml-2" />
            </span>
          </p>
        </div>
      </div>
    </Link>
  );
};

export default FeaturedCard;
